<template>
  <div class="record__store">
    <SectionTitle title="各門市統計" fontSize="18" hideBtn hideTitleStick />
    <el-table
      v-loading="loading"
      class="record__table"
      empty-text="暫無數據"
      :data="storeList"
      :header-cell-style="{ 'text-align': 'center' }"
      :cell-style="{ 'text-align': 'center' }"
    >
      <EmptyBlock slot="empty" />
      <el-table-column label="門市" props="name" sortable>
        <template slot-scope="scope">
          {{ scope.row.name }}
        </template>
      </el-table-column>
      <el-table-column label="實際回饋金額" props="name" sortable>
        <template slot-scope="scope">
          {{ totalAdd(scope.row.id) || 0 }}
        </template>
      </el-table-column>
      <el-table-column label="實際使用金額" props="name" sortable>
        <template slot-scope="scope">
          {{ totalUse(scope.row.id) || 0 }}
        </template>
      </el-table-column>
    </el-table>
    <Pagination
      :pageLimit="10"
      :total="storeCount"
    />
  </div>
</template>

<script>
import { defineComponent, computed, ref, onMounted, watch } from 'vue'
import { checkUserFeature } from '@/store/modules/permission'
import store from '@/store'
import { GetOriginTotalAmountByBranch } from '@/api/rebate'
import { FindStoreCount, FindStore } from '@/api/store'
import dayjs from 'dayjs'

export default defineComponent({
  name: 'RebateRecordStoreTable',
  props: ['dateRange'],
  setup (props) {
    const loading = ref(false)
    const storeList = ref([])
    const storeCount = ref(0)
    const storeStart = ref(0)
    const storeLimit = 15
    const originTotalAmountByBranchList = ref([])
    const userPlanFeature = computed(() => store.getters.userPlanFeature)
    const userFeatures = computed(() => store.getters.userFeatures)

    const useStorePermission = computed(() => {
      return checkUserFeature(userPlanFeature.value, userFeatures.value, 'admin.branch.adminUseStore')
    })
    const shopId = computed(() => store.getters.shop)

    onMounted(() => {
      findStoreCount()
      findStore()
      getOriginTotalAmountByBranch()
      console.log('onMounted')
    })

    watch(() => props.dateRange, () => {
      getOriginTotalAmountByBranch()
    })

    const findStoreCount = async () => {
      loading.value = true
      const [res, err] = await FindStoreCount({
        shopId: shopId.value,
      })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      storeCount.value = res.count
    }

    const findStore = async () => {
      loading.value = true
      const [res, err] = await FindStore({
        shopId: shopId.value,
        start: storeStart.value,
        limit: storeLimit.value,
      })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      console.log('store', res)
      storeList.value = res
    }

    const getOriginTotalAmountByBranch = async () => {
      loading.value = true
      console.log('props.dateRange', props.dateRange)
      const [res, err] = await GetOriginTotalAmountByBranch({
        shopId: shopId.value,
        createStart: dayjs(props.dateRange[0]).toDate(),
        createEnd: dayjs(props.dateRange[1]).toDate(),
      })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      originTotalAmountByBranchList.value = res
    }

    const totalAdd = (id) => {
      const arr = originTotalAmountByBranchList.value.filter((item) => {
        return item.branchId === id
      })
      return arr.reduce((prev, curr) => {
        if (curr.origin === 'ADMIN_ADD' || curr.origin === 'EC_ORDER_ADD' || curr.origin === 'SALE_RECORD_ADD') {
          return prev + curr.totalAmount
        }
        return prev
      }, 0)
    }

    const totalUse = (id) => {
      const arr = originTotalAmountByBranchList.value.filter((item) => {
        return item.branchId === id
      })
      return arr.reduce((prev, curr) => {
        if (curr.origin === 'EC_ORDER_USE' || curr.origin === 'SALE_RECORD_USE') {
          return prev + curr.totalAmount
        }
        return prev
      }, 0)
    }

    return {
      useStorePermission,
      loading,
      storeCount,
      storeList,
      totalAdd,
      totalUse,
    }
  },
})
</script>

<style scoped lang="scss">
.record__store {
margin-top: 20px
}
</style>
