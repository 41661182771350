import { render, staticRenderFns } from "./YearStatistic.vue?vue&type=template&id=3ba4b367&scoped=true"
import script from "./YearStatistic.vue?vue&type=script&setup=true&lang=js"
export * from "./YearStatistic.vue?vue&type=script&setup=true&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ba4b367",
  null
  
)

export default component.exports