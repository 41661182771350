import { reactive } from 'vue'
import { ExportExcel } from '@/utils/excel'

export const useExport = () => {
  const exportState = reactive({
    title: '',
    modal: false,
    exportting: false,
    error: false,
    exportData: null,
    dataCount: 0,
    percentage: 0,
  })
  const resetExport = () => {
    exportState.title = ''
    exportState.modal = false
    exportState.exportting = false
    exportState.error = false
    exportState.exportData = null
    exportState.dataCount = 0
    exportState.percentage = 0
  }

  const calExportPercentage = (curCount) => {
    exportState.percentage = (curCount / exportState.dataCount) * 100
  }

  const getExportData = async (fetchAPI, payload, throwErr) => {
    let start = 0
    const data = []
    const _limit = 100

    while (true) {
      const [res, err] = await fetchAPI({
        ...payload,
        start,
        limit: _limit,
      })
      if (err) {
        if (throwErr) throw err
        window.$message.error(err)
        return
      }
      start += _limit
      data.push(...res)
      calExportPercentage(data.length)
      if (res.length < _limit) {
        break
      }
    }

    return data
  }

  return {
    exportState,
    resetExport,
    ExportExcel,
    getExportData,
  }
}
