import { admin2 } from '@/api/instance'

export const FindStore = async ({
  shopId,
  start,
  limit,
  name,
}) => {
  return await admin2({
    method: 'get',
    url: `/${shopId}/branch`,
    params: {
      start,
      limit,
      name,
    },
  })
}

export const CreateStore = async ({
  shopId,
  name,
  address,
  phone,
}) => {
  return await admin2({
    method: 'post',
    url: `/${shopId}/branch`,
    data: {
      name,
      address,
      phone,
    },
  })
}

export const UpdateStore = async ({
  shopId,
  storeId,
  name,
  address,
  phone,
}) => {
  return await admin2({
    method: 'put',
    url: `/${shopId}/branch/${storeId}`,
    data: {
      name,
      address,
      phone,
    },
  })
}

export const FindStoreCount = async ({
  shopId,
  name,
}) => {
  return await admin2({
    method: 'get',
    url: `/${shopId}/branch/count`,
    data: {
      name,
    },
  })
}
